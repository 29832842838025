import { List, ListItem, ListItemButton } from "@mui/joy";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { app } from "../AppContext";
import { AppModal } from "./AppModal";
import { CloseButton } from "./CloseButton";

export function LoadMapDialog(props: {
	onSubmit: (id: string) => Promise<void>,
	onClose: () => void
})
{
	const [files, setFiles] = React.useState<gapi.client.drive.File[]>([]);

	React.useEffect(() => {
		loadFiles().catch(e => console.error(e));
	}, []);

	const loadFiles = async () => {
		try {
			const files = await app.googleDriveService.loadFilesOfMaps();
			if (files) {
				setFiles(files);
			}
		} catch (e) {
			console.error(e);
			app.showError("Failed to load maps", e);
			props.onClose();
		}
	};

	return (
		<AppModal open={true} onClose={() => props.onClose()}>
			<ModalDialog
				aria-labelledby="basic-modal-dialog-title"
				aria-describedby="basic-modal-dialog-description"
				sx={{maxWidth: 500}}
			>
				<CloseButton onClick={props.onClose} />
				<Typography id="basic-modal-dialog-title" component="h2">
					Open map
				</Typography>
				<List>
					{files.map(file => <ListItem key={file.id}>
						<ListItemButton onClick={() => {
							props.onClose();
							void props.onSubmit(file.id!);
						}}>
							{file.name}
						</ListItemButton>
					</ListItem>)}
				</List>
			</ModalDialog>
		</AppModal>
	);
}

