import { Button, Card, Chip, CircularProgress, Divider, IconButton, Slider, Stack, Switch, Typography } from "@mui/joy";
import React from "react";
import { app } from "../AppContext";
import { IMapProps } from "../map/MapModel";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { ComboButton } from "./ComboButton";
import { LoadMapDialog } from "./LoadMapDialog";
import { LoginControls } from "./LoginControls";
import { MapPropsDialog } from "./MapPropsDialog";
import WarningIcon from "@mui/icons-material/Error";
import { Undo, Redo } from "@mui/icons-material";
import { MapNewVersionDialog } from "./MapNewVersionDialog";
import { ErrorDialog } from "./ErrorDialog";

let applyPhantomPercentTimeout: NodeJS.Timeout | undefined;

export function ControlsPanel()
{
	const [propsDialogOpen, setPropsDialogOpen] = React.useState<boolean>(false);
	const [propsDialogMode, setPropsDialogMode] = React.useState<"new" | "edit">("new");
	const [showLabels, setShowLabels] = React.useState<boolean>(app.state.showLabels === "id");
	const [showTypes, setShowTypes] = React.useState<boolean>(app.state.showLabels === "type");
	const [loadDialogOpen, setLoadDialogOpen] = React.useState<boolean>(false);
	const [errorDialogOpen, setErrorDialogOpen] = React.useState<boolean>(false);
	const [newVersionDialogOverrideOpen, setNewVersionDialogOverrideOpen] =
		React.useState<boolean>(false);
	const [isNewVersionPresent, setIsNewVersionPresent] = React.useState<boolean>(false);
	const [newVersionDialogLoadOpen, setNewVersionDialogLoadOpen] = React.useState<boolean>(false);
	const [loggedIn, setLoggedIn] = React.useState(false);
	const [canUndo, setCanUndo] = React.useState(false);
	const [canRedo, setCanRedo] = React.useState(false);
	const [isDisabledToolbarButtons, setIsDisabledToolbarButtons] = React.useState(false);
	const [isRequestWasSent, setIsRequestWasSent] = React.useState(false);
	const [phantomPercent, setPhantomPercent] = React.useState<number>(app.state.phantomPercent || 50);

	React.useEffect(() => {
		setTimeout(() => {
			void checkMapVersion();
		}, 1000);
		app.mapEditor.commandHistory.changeEvent.addListener(handleChangeCommandHistory);
		return () => app.mapEditor.commandHistory.changeEvent.removeListener(handleChangeCommandHistory);
	}, []);

	const checkMapVersion = async () => {
		const fileName = `${app.map.name}.json`;
		try {
			const files = await app.googleDriveService.findFilesOfMap(fileName);
			if (files && files.length > 0 && files[0].id) {
				const mapFromGoogleDrive = await app.googleDriveService.loadMap(files[0].id);
				if (mapFromGoogleDrive && app.map.isNewVersionPresent(mapFromGoogleDrive)) {
					setNewVersionDialogLoadOpen(true);
					setIsNewVersionPresent(true);
					setIsDisabledToolbarButtons(true);
					return;
				}
			}
		} catch (e) {
			setErrorDialogOpen(true);
			console.error(e);
		}
	};

	const handleChangeCommandHistory = () => {
		setCanUndo(app.mapEditor.commandHistory.canUndo);
		setCanRedo(app.mapEditor.commandHistory.canRedo);
	};

	const newMap = () => {
		setPropsDialogMode("new");
		setIsDisabledToolbarButtons(true);
		setPropsDialogOpen(true);
	};

	const editMap = () => {
		setPropsDialogMode("edit");
		setIsDisabledToolbarButtons(true);
		setPropsDialogOpen(true);
	};

	const loadMap = () => {
		app.gapiClient.checkAccess();
		setIsDisabledToolbarButtons(true);
		setLoadDialogOpen(true);
	};

	const handleLoadMap = async (id?: string) => {
		setIsRequestWasSent(true);
		setIsDisabledToolbarButtons(true);
		try {
			const fileName = `${app.map.name}.json`;
			const files = await app.googleDriveService.findFilesOfMap(fileName);
			const mapFromGoogleDrive = await app.googleDriveService.loadMap(id ? id : files![0].id!);
			app.map.setMap(mapFromGoogleDrive!);
			app.mapPhantom.update(phantomPercent, true);
			app.mapView.buildMap();
			app.showSuccess("Map loaded successfully");
			setIsNewVersionPresent(false);
		} catch (e) {
			console.error(e);
			app.showError("Failed to load map", e);
		} finally {
			setIsDisabledToolbarButtons(false);
			setIsRequestWasSent(false);
		}
	};

	const saveMap = async () => {
		app.gapiClient.checkAccess();
		setIsDisabledToolbarButtons(true);
		setIsRequestWasSent(true);
		const mapData = JSON.stringify(app.map.getMap());
		const fileName = `${app.map.name}.json`;
		let dialogIsOpen: boolean = false;

		try {
			const files = await app.googleDriveService.findFilesOfMap(fileName);
			if (files && files.length > 0 && files[0].id) {
				const mapFromGoogleDrive = await app.googleDriveService.loadMap(files[0].id);
				if (!newVersionDialogOverrideOpen && mapFromGoogleDrive && app.map.isNewVersionPresent(mapFromGoogleDrive)) {
					setNewVersionDialogOverrideOpen(true);
					setIsRequestWasSent(false);
					dialogIsOpen = true;
					return;
				}
				app.map.updateTimeStamp();
				app.saveMapInStorage();
				const mapDataWithNewTimeStamp = JSON.stringify(app.map.getMap());
				await app.googleDriveService.updateMapFile(files[0].id, fileName, mapDataWithNewTimeStamp);
			} else {
				await app.googleDriveService.createMapFile(fileName, mapData);
			}
			app.showSuccess("Map saved successfully");
			setIsNewVersionPresent(false);
		} catch (e) {
			console.error(e);
			app.showError("Failed to save map");
		} finally {
			if (!dialogIsOpen) {
				setIsDisabledToolbarButtons(false);
				setIsRequestWasSent(false);
			}
		}
	};

	const exportMap = () => {
		const data = JSON.stringify(app.map.getMap().tiles);
		const link = document.createElement("a");
		const file = new Blob([data], {type: "text/plain"});
		link.href = URL.createObjectURL(file);
		link.download = `${app.map.name}.json`;
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	const importMap = () => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = ".json";
		input.onchange = (e: any) => {
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const mapData = JSON.parse(e.target.result);
				try {
					app.map.importMap(mapData, file.name.substring(0, file.name.lastIndexOf(".")));
					app.mapPhantom.update(phantomPercent, true);
					app.mapView.buildMap();
					app.showSuccess("Map loaded successfully");
				} catch (e) {
					console.error(e);
					app.showError("Failed to load map", e);
				}
			};
			reader.onerror = (e: any) => {
				console.error(e);
				app.showError("Failed to load map", e);
			};
			reader.readAsText(file);
		};
		document.body.appendChild(input);
		input.click();
		input.remove();
	};

	const handleMapPropsDialogClose = (data?: IMapProps) => {
		setPropsDialogOpen(false);
		setIsDisabledToolbarButtons(false);
		if (!data)
			return;

		if (propsDialogMode === "new") {
			app.map.initMap(data.size, data.name, data.baseCellsByType);
			app.mapPhantom.update(phantomPercent, true);
			app.mapView.buildMap();
		} else {
			const oldSize = app.map.size;
			const oldReadonlyBaseTiles = app.map.readonlyBaseTiles;
			app.map.setProps(data);
			if (oldSize !== data.size || oldReadonlyBaseTiles !== data.readOnlyBaseTiles) {
				app.mapPhantom.update(phantomPercent, true);
				app.mapView.buildMap();
			}
		}
	};

	const handleChangeShowMapLabels = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShowTypes(false);
		setShowLabels(e.target.checked);
		app.mapView.showLabels(e.target.checked && "id");
	};

	const handleChangeShowMapTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShowLabels(false);
		setShowTypes(e.target.checked);
		app.mapView.showLabels(e.target.checked && "type");
	};

	const handleCloseLoadDialog = () => {
		setLoadDialogOpen(false);
		setIsDisabledToolbarButtons(false);
	};

	const handleGenerate = () => {
		setIsDisabledToolbarButtons(true);
		if (app.map.stats.freeDecorationCells > 0) {
			app.showError("Map has free decoration cells. Please fill them before generating base cells");
			setIsDisabledToolbarButtons(false);
			return;
		}

		if (app.map.stats.freeDecorationCells < 0) {
			app.showError("Map has no enough free cells. Please remove some decorations before generating base cells");
			setIsDisabledToolbarButtons(false);
			return;
		}

		if (!app.map.checkCells()) {
			app.showError("Map has invalid cells. Please fix them before generating base cells");
			setIsDisabledToolbarButtons(false);
			return;
		}

		app.map.generateBaseCells();
		app.mapPhantom.update(phantomPercent);
		app.mapView.buildMap();
		app.showSuccess("Base cells was generated successfully");
		setIsDisabledToolbarButtons(false);
	};

	const handleFlipY = () => {
		app.map.flipY();
		app.mapView.buildMap();
		app.showSuccess("Map was flipped successfully");
	};

	const handleClear = () => {
		app.map.clearBaseCells();
		app.mapPhantom.update(phantomPercent, true);
		app.mapView.buildMap();
		app.showSuccess("Base cells was cleared successfully");
	};

	const handleChangePhantomPercent = (event: Event, newValue: number | number[]) => {
		const value = newValue as number;
		setPhantomPercent(value);

		if (applyPhantomPercentTimeout)
			clearTimeout(applyPhantomPercentTimeout);

		applyPhantomPercentTimeout = setTimeout(() => {
			app.mapPhantom.update(value);
			app.mapView.buildMap();
			app.updateState({phantomPercent: value});
		}, 300);
	};

	return (
		<Stack direction="row" margin={1} spacing={1}>
			<IconButton variant="outlined" disabled={!canUndo || isDisabledToolbarButtons}
			            onClick={() => app.mapEditor.commandHistory.undo()}>
				<Undo />
			</IconButton>
			<IconButton variant="outlined" disabled={!canRedo || isDisabledToolbarButtons}
			            onClick={() => app.mapEditor.commandHistory.redo()}>
				<Redo />
			</IconButton>
			<Divider orientation="vertical" />

			<Button disabled={isDisabledToolbarButtons} variant="outlined" onClick={newMap}>New</Button>
			<ComboButton disabled={isDisabledToolbarButtons} variant="outlined" onClick={editMap} options={[
				{label: "Flip Y", onClick: handleFlipY, disabled: app.map.readonlyBaseTiles || isDisabledToolbarButtons},
			]}>Edit</ComboButton>
			<ComboButton variant="outlined" onClick={loadMap} disabled={!loggedIn || isDisabledToolbarButtons}
			             options={[
				             {label: "Import", onClick: importMap, disabled: isDisabledToolbarButtons},
			             ]}>Load</ComboButton>
			<ComboButton variant="outlined" onClick={() => {
				void saveMap();
			}}
			             disabled={!loggedIn || isDisabledToolbarButtons}
			             options={[
				             {label: "Export", onClick: exportMap, disabled: isDisabledToolbarButtons},
			             ]}>Save</ComboButton>
			<ComboButton variant="outlined" onClick={handleGenerate}
			             disabled={app.map.readonlyBaseTiles || isDisabledToolbarButtons} options={[
				{label: "Clear", onClick: handleClear, disabled: app.map.readonlyBaseTiles || isDisabledToolbarButtons},
				<div>
					<Typography id="phantom-slider" fontSize="14px">
						Phantom bases
					</Typography>
					<Slider aria-labelledby="phantom-slider"
					        value={phantomPercent}
					        onChange={handleChangePhantomPercent}
					        sx={{paddingY: 0}}
					/>
				</div>,
			]}>Generate</ComboButton>

			{isRequestWasSent &&
			 <CircularProgress />}

			{isNewVersionPresent &&
			 <Stack style={{display: "flex", justifyContent: "center", alignItems: "center", margin: "auto"}}>
				 <WarningIcon color="warning" />
				 <Typography>There is a new version on Google Drive!</Typography>
			 </Stack>}

			<Stack direction="row" spacing={1} sx={{marginLeft: "auto !important"}}>
				<Card variant="outlined" size="sm">
					<Typography component="div" level="body1">
						{app.map.name}
						<Chip size="sm" sx={{ml: 1, verticalAlign: "bottom"}}>
							{app.map.size}x{app.map.size}
						</Chip>
					</Typography>
				</Card>

				<Switch
					endDecorator="Labels"
					variant="outlined"
					checked={showLabels}
					onChange={handleChangeShowMapLabels}
				/>

				<Switch
					endDecorator="Types"
					variant="outlined"
					checked={showTypes}
					onChange={handleChangeShowMapTypes}
				/>

				<Divider orientation="vertical" />
				<ColorModeSwitcher />
				<LoginControls onLoggedInChange={(loggedIn) => setLoggedIn(loggedIn)} />
			</Stack>
			{propsDialogOpen && <MapPropsDialog
				data={propsDialogMode === "edit" ? {
					name: app.map.name,
					size: app.map.size,
					readOnlyBaseTiles: app.map.readonlyBaseTiles,
					baseCellsByType: app.map.baseCellsByType,
					decorationsCells: app.map.stats.decorationCells - app.map.stats.freeDecorationCells,
				} : undefined}
				onClose={handleMapPropsDialogClose}
			/>}
			{loadDialogOpen && <LoadMapDialog
				onSubmit={handleLoadMap}
				onClose={handleCloseLoadDialog}
			/>}
			{newVersionDialogOverrideOpen && <MapNewVersionDialog
				onClose={() => {
					setNewVersionDialogOverrideOpen(false);
					setIsDisabledToolbarButtons(false);
				}}
				onSubmit={saveMap}
				closeButtonName="Cancel"
				confirmButtonName="Override"
				textMessage="There is a new version of this file on Google Drive!"
			/>}
			{newVersionDialogLoadOpen && <MapNewVersionDialog
				onClose={() => {
					setNewVersionDialogLoadOpen(false);
					setIsDisabledToolbarButtons(false);
				}}
				onSubmit={handleLoadMap}
				closeButtonName="Ignore"
				confirmButtonName="Load"
				textMessage="There is a new version of this file on Google Drive!"
			/>}
			{errorDialogOpen && <ErrorDialog
				onClose={() => setErrorDialogOpen(false)}
				textMessage="Cannot check file version on Google Drive!"
			/>}
		</Stack>
	);
}